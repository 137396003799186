import formatISO from 'date-fns/formatISO';
import { COMPANY_PLAN_TYPES, providers } from 'assets/constants';
import { createVumiJotformLink, createJotformLink } from 'assets/scripts/jotform-claims';
import posthog from 'posthog-js';

export const loadImage = (src: string) =>
  new Promise((resolve) => {
    const loader = new Image();
    loader.onload = resolve;
    loader.src = src;
    // Failsafe
    setTimeout(resolve, 1000);
  });

export const toggleScrollLock = (isLocked: boolean) => {
  const $body: any = document.querySelector('body');
  if (isLocked) {
    $body.style.overflow = 'hidden';
    $body.style.height = '100vh';
  } else {
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('height');
  }
};

export function isValidEmail(value: string) {
  const testReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return testReg.test(value);
}

export const isVumi = (provider: string) =>
  provider === providers.VUMI_VIP || provider === providers.VUMI_BASIC_FLEX;

export const getVumiLink = (planholder: any, insurancePlan: any) =>
  createVumiJotformLink(
    'https://form.jotform.com/220486431736356?',
    planholder,
    insurancePlan
  );

const getNowLink = (planholder: any, insurancePlan: any) =>
  createJotformLink(
    'https://form.jotform.com/210557032591148?',
    planholder,
    insurancePlan
  );

const verifyNowInsurancePlan = (planholder: any, insurancePlan: any) => {
  if (insurancePlan?.policyID) {
    if (insurancePlan.policyID.match(/^([a-zA-Z]{4})CC.*/)) {
      return getNowLink(planholder, insurancePlan);
    }
    if (insurancePlan.policyID.match(/^\d00\d{7}$/)) {
      return getVumiLink(planholder, insurancePlan);
    }
  }
  return '';
};

export const getClaimsForm = (planholder: any, insurancePlan: any) => {
  /**
   * https://www.notion.so/safetywing/Individual-members-associated-with-Vumi-are-being-sent-to-Now-claims-form
   * -df5807bb9f5748a785afe76801d903a6
   * Currently, many contracts are associated with Now Health despite being actually VUMI.
   * So, double check based on the policy id format
   */

  switch (insurancePlan?.insuranceName) {
    case providers.NOW_HEALTH_GLOBAL:
      return verifyNowInsurancePlan(planholder, insurancePlan);
    case providers.VUMI_VIP:
    case providers.VUMI_BASIC_FLEX:
      return getVumiLink(planholder, insurancePlan);
    case providers.NOW_HEALTH_US:
    case providers.UNITED_HEALTHCARE:
    default:
      return '';
  }
};

interface PolicyDocument {
  name: string;
  plans: number[];
  planType: (typeof COMPANY_PLAN_TYPES)[keyof typeof COMPANY_PLAN_TYPES];
  policyDocument: string;
}

const policyDocuments: PolicyDocument[] = [
  {
    name: 'Community 2.0 Standard',
    plans: [1, 4],
    planType: COMPANY_PLAN_TYPES.STANDARD,
    policyDocument: '/remote-health/standard-community-plan-2.0-PR.pdf'
  },
  {
    name: 'Community 2.0 Premium',
    plans: [2, 5],
    planType: COMPANY_PLAN_TYPES.PREMIUM,
    policyDocument: '/remote-health/premium-community-plan-2.0-PR.pdf'
  },
  {
    name: 'Community 2.0 Premium + maternity',
    plans: [3, 6],
    planType: COMPANY_PLAN_TYPES.MATERNITY,
    policyDocument: '/remote-health/premium+maternity-community-plan-2.0-PR.pdf'
  },
  {
    name: 'Affinity 2.0 Standard',
    plans: [11],
    planType: COMPANY_PLAN_TYPES.AFFINITY_STANDARD,
    policyDocument: '/remote-health/standard-affinity-plan-2.0.pdf'
  },
  {
    name: 'Affinity 2.0 Premium',
    plans: [12],
    planType: COMPANY_PLAN_TYPES.AFFINITY_PREMIUM,
    policyDocument: '/remote-health/premium-affinity-plan-2.0.pdf'
  },
  {
    name: 'Affinity 2.0 Premium+',
    plans: [13],
    planType: COMPANY_PLAN_TYPES.AFFINITY_MATERNITY,
    policyDocument: '/remote-health/premium+maternity-affinity-plan-2.0.pdf'
  },
  {
    name: 'Affinity 1.0 / Deel',
    plans: [14],
    planType: COMPANY_PLAN_TYPES.AFFINITY_OLD,
    policyDocument: '/nomad-health/Deel-Policy-document.pdf'
  },
  {
    name: 'Individual/Nomad 2.0 Standard',
    plans: [15, 18],
    planType: COMPANY_PLAN_TYPES.INDIVIDUAL_STANDARD,
    policyDocument: '/remote-health/individual-standard-plan.pdf'
  },
  {
    name: 'Individual/Nomad 2.0 Premium',
    plans: [16, 19],
    planType: COMPANY_PLAN_TYPES.INDIVIDUAL_PREMIUM,
    policyDocument: '/remote-health/individual-premium-plan.pdf'
  },
  {
    name: 'Individual/Nomad 2.0 Premium Plus',
    plans: [17, 20],
    planType: COMPANY_PLAN_TYPES.INDIVIDUAL_PREMIUM,
    policyDocument: '/remote-health/individual-premium--plus-plan.pdf'
  },
  {
    name: 'Community 3.0 Essential',
    plans: [21],
    planType: COMPANY_PLAN_TYPES.ESSENTIAL,
    policyDocument:
      '/remote-health/policy-documents/v3/essential-community-plan-3-0V2023-12-15.pdf'
  },
  {
    name: 'Community 3.0 Standard',
    plans: [22, 25],
    planType: COMPANY_PLAN_TYPES.STANDARD,
    policyDocument:
      '/remote-health/policy-documents/v3/standard-community-plan-3-0V2023-12-15.pdf'
  },
  {
    name: 'Community 3.0 Premium',
    plans: [23, 26],
    planType: COMPANY_PLAN_TYPES.PREMIUM,
    policyDocument:
      '/remote-health/policy-documents/v3/premium-community-plan-3-0V2023-12-15.pdf'
  },
  {
    name: 'Community 3.0 Premium +',
    plans: [24, 27],
    planType: COMPANY_PLAN_TYPES.MATERNITY,
    policyDocument:
      '/remote-health/policy-documents/v3/premium-plus-community-plan-3-0V2023-12-15.pdf'
  },
  {
    name: 'Affinity 3.0 Standard',
    plans: [28, 31],
    planType: COMPANY_PLAN_TYPES.AFFINITY_STANDARD,
    policyDocument:
      '/remote-health/policy-documents/v3/standard-affinity-3-0-plan-2024-04.pdf'
  },
  {
    name: 'Affinity 3.0 Premium',
    plans: [29, 32],
    planType: COMPANY_PLAN_TYPES.AFFINITY_PREMIUM,
    policyDocument:
      '/remote-health/policy-documents/v3/premium-affinity-3-0-plan-2024-04.pdf'
  },
  {
    name: 'Affinity 3.0 Premium +',
    plans: [30, 33],
    planType: COMPANY_PLAN_TYPES.AFFINITY_MATERNITY,
    policyDocument:
      '/remote-health/policy-documents/v3/premium_plus-affinity-3-0-plan-2024-04.pdf'
  },
  {
    name: 'Affinity Essential 3.0',
    plans: [40],
    planType: COMPANY_PLAN_TYPES.AFFINITY_ESSENTIAL,
    policyDocument:
      '/remote-health/policy-documents/v3/essential-affinity-3-0-plan-2024-04.pdf'
  },
  {
    name: 'Nomad Health Standard 3.0',
    plans: [34, 37],
    planType: COMPANY_PLAN_TYPES.AFFINITY_ESSENTIAL,
    policyDocument:
      '/remote-health/policy-documents/v3/nomad-health-standard-plan-3.0.pdf'
  },
  {
    name: 'Nomad Health Premium 3.0',
    plans: [35, 38],
    planType: COMPANY_PLAN_TYPES.AFFINITY_ESSENTIAL,
    policyDocument: '/remote-health/policy-documents/v3/nomad-health-premium-plan-3.0.pdf'
  },
  {
    name: 'Nomad Health Premium+ 3.0',
    plans: [36, 39],
    planType: COMPANY_PLAN_TYPES.AFFINITY_ESSENTIAL,
    policyDocument:
      '/remote-health/policy-documents/v3/nomad-health-premium-plus-plan-3.0.pdf'
  }
];

const getPolicyLinkForProvider = (provider: string) => {
  switch (provider) {
    case providers.NOW_HEALTH_GLOBAL:
      return '/remote-health/MemberHandbook-04-2021.pdf';
    case providers.VUMI_VIP:
      return '/remote-health/MemberHandbook-Vumi-V2.pdf';
    case providers.VUMI_BASIC_FLEX:
    case providers.STANDARD_OLD:
    case providers.VUMI_DEEL:
      return '/nomad-health/Deel-Policy-document.pdf';
    case providers.NOW_HEALTH_US:
    default:
      return '';
  }
};

export const getPolicyDocument = (
  providerOrPlanType: string,
  planId?: number
): string => {
  let policyDocument = '';
  if (providerOrPlanType in COMPANY_PLAN_TYPES) {
    policyDocument =
      policyDocuments.find(
        (doc) =>
          doc.planType === providerOrPlanType && planId && doc.plans.includes(planId)
      )?.policyDocument || '';
  }

  if (policyDocument === '') {
    return getPolicyLinkForProvider(providerOrPlanType);
  }
  return policyDocument;
};

export const getFAQLink = (provider: string) => {
  switch (provider) {
    case COMPANY_PLAN_TYPES.INDIVIDUAL_STANDARD:
    case COMPANY_PLAN_TYPES.INDIVIDUAL_PREMIUM:
    case COMPANY_PLAN_TYPES.INDIVIDUAL_MATERNITY:
    case COMPANY_PLAN_TYPES.AFFINITY_STANDARD:
    case COMPANY_PLAN_TYPES.AFFINITY_PREMIUM:
    case COMPANY_PLAN_TYPES.AFFINITY_MATERNITY:
    case COMPANY_PLAN_TYPES.AFFINITY_OLD:
      return '/nomad-health/faq';
    case providers.NOW_HEALTH_GLOBAL:
      return '/faq/now_health_global';
    case providers.VUMI_VIP:
      return '/faq/vumi_vip';
    case providers.VUMI_BASIC_FLEX:
      return '/faq/vumi_basic_flex';
    case providers.NOW_HEALTH_US:
    case providers.UNITED_HEALTHCARE:
    default:
      return '/faq';
  }
};

export const getAnnualMaximum = (provider: string) => {
  switch (provider) {
    case COMPANY_PLAN_TYPES.STANDARD:
    case COMPANY_PLAN_TYPES.PREMIUM:
    case COMPANY_PLAN_TYPES.MATERNITY:
    case COMPANY_PLAN_TYPES.INDIVIDUAL_STANDARD:
    case COMPANY_PLAN_TYPES.INDIVIDUAL_PREMIUM:
    case COMPANY_PLAN_TYPES.INDIVIDUAL_MATERNITY:
    case COMPANY_PLAN_TYPES.AFFINITY_STANDARD:
    case COMPANY_PLAN_TYPES.AFFINITY_PREMIUM:
    case COMPANY_PLAN_TYPES.AFFINITY_MATERNITY:
    case COMPANY_PLAN_TYPES.AFFINITY_OLD:
    case providers.VUMI_VIP:
    case providers.VUMI_BASIC_FLEX:
      return 1500000;
    case providers.NOW_HEALTH_US:
    case providers.UNITED_HEALTHCARE:
    case providers.NOW_HEALTH_GLOBAL:
    default:
      return 1000000;
  }
};

export const formatDateRh = (date: Date) => {
  const dateInUTC = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );

  return formatISO(dateInUTC);
};

/* Create new Date in Etc/UTC timezone */
export const today = () => new Date().toLocaleString('en-US', { timeZone: 'Etc/UTC' });

/**
 * @deprecated use date-fns instead: differenceInYears(startingDate, dateOfBirth)
 */
export function getAge(dateOfBirth: string, startingDate: string) {
  const todaysDate = new Date(startingDate);
  const birthDate = new Date(dateOfBirth);
  let age = todaysDate.getFullYear() - birthDate.getFullYear();
  const m = todaysDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && todaysDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const orEmpty = (value: any) => value || '';

export const openLink = (link: string) => {
  const win = window.open(link, '_blank', 'noopener,noreferrer');
  if (win !== null) win.focus();
};

export const openLinkWithoutFocus = (link: string) => {
  window.open(link, '_blank', 'noopener,noreferrer');
};

export const openCallLink = () => {
  try {
    // Track book-a-call pageviews with PostHog
    posthog.capture('$pageview', {
      $current_url: `${window.location.origin}/remote-health/lets-connect-posthog-url`
    });
    posthog.capture('book_a_call_clicked');
  } catch (error) {
    // ignore
  }
  openLink('https://hello.safetywing.com/lets-connect');
};

export const getMemberCost = (age: string, companyPlan: any) =>
  companyPlan?.pricing?.find(
    (price: any) => price?.lowerAgeLimit === parseInt(age?.split(/\W/)?.[0], 10)
  )?.pricingPerMember || 0;

export const stringsAsListInSentence = (strings: string[]) => {
  if (strings.length > 1) {
    const allButLast = strings.slice(0, -1).join(', ');
    const last = strings[strings.length - 1];
    return `${allButLast} and ${last}`;
  }
  return strings.join('');
};

export const saveQueryParam = (name: string, value: any) => {
  window.localStorage.setItem(name, value);
};

export const removeQueryParam = (name: string) => {
  window.localStorage.removeItem(name);
};
