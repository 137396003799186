import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { put, get, post, del } from 'api/fetch';
import { isValidEmail } from 'assets/helpers';
import produce from 'immer';
import { isCompanyContractHybrid } from '../helpers';
import { LoadingStatus, FinchConnectionError } from './types';

const getSortKey = (value) =>
  ({
    locationCountry: 'COUNTRY',
    lastName: 'NAME',
    email: 'EMAIL',
    groupId: 'GROUP'
  }[value]);

export const queryMembers = async ({ page, pageSize, filters, sort, searchValue }) => {
  const { status, fullLocationCountry, groupId } = filters || {};
  const queryParams = pageSize
    ? new URLSearchParams({ page, size: pageSize })
    : new URLSearchParams({});

  if (status) queryParams.set('filterStatus', status);
  if (fullLocationCountry) queryParams.set('filterCountry', fullLocationCountry);
  if (groupId) queryParams.set('filterGroupId', groupId);
  if (sort?.value) {
    queryParams.set('sortOn', getSortKey(sort.value));
    queryParams.set('sortDirection', sort.direction);
  }
  if (searchValue) {
    queryParams.set('search', searchValue);
  }

  const url = `/rh/companies/members-with-contract-info?${queryParams}`;
  const response = await get(url, { api: 'rh' });
  return response;
};

export const fetchMemberCountsByStatus = createAsyncThunk(
  'remote_health/CompanyProfile/insurance/fetchMemberCountsByStatus',
  async () => {
    return get(`/rh/companies/member-count-by-status`, { api: 'rh' });
  }
);

export const fetchMembers = createAsyncThunk(
  'remote_health/CompanyProfile/insurance/fetchMembers',
  queryMembers
);

const finchIntegrationInitialState = {
  loading: {
    createConnection: LoadingStatus.idle,
    fetchRemoteIndividualsFromConnection: LoadingStatus.idle,
    importingMembers: LoadingStatus.idle
  },
  error: {
    fetchRemoteIndividualsFromConnection: null
  },
  currentConnection: null,
  remoteIndividuals: {
    available: null,
    unmappable: null
  },
  invitedMembers: []
};

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/CompanyProfile/insurance',
  initialState: {
    isLoading: false,
    isLoadingMembers: false,
    isLoadingDraftMembers: false,
    isLoadingCosts: false,
    isLoadingMemberStatusTotals: false,
    error: null,
    errorMessage: null,
    contractForAdmin: null,
    isLoadingContract: false,
    draftMembers: [],
    newDraftMembers: [],
    draftMembersFailed: [],
    members: [],
    memberCosts: [],
    membersFailed: [],
    memberGroups: {
      isLoading: true, // initialize to true for easier handling in components
      isAddLoading: LoadingStatus.idle,
      error: null,
      addError: null,
      list: []
    },
    cancellationReasons: [],
    inviteSettings: {
      startDate: null,
      plan: null,
      groupId: null
    },
    inviteEmails: [],
    invalidEmails: [],
    membersToImport: null,
    shouldRefreshMembers: false,
    dashboardSettings: {
      searchValue: null,
      /** The filters possible on the dashboard.
       * Should have the same name as the properties of the mapped members */
      filters: {
        status: null,
        fullLocationCountry: null,
        groupId: null
      },
      sort: {
        value: null, // Status, Name, Email, Location, Family members, Plan, Cost,
        direction: null // ASC, DESC
      },
      page: 0,
      pageSize: 25
    },
    totalPages: null, // set to null instead of 0 so we can tell the difference between not-fetched and fetched but empty
    statusTotals: null,
    /**
     * Using boolean instead of checking statusTotals null to prevent endless firing on a failure
     */
    statusTotalsLoaded: false,
    monthlyTotal: 0,
    finchConnectionsLoading: LoadingStatus.idle,
    finchConnections: [],
    finchIntegration: finchIntegrationInitialState
  },
  reducers: {
    fetchGroupsRequest: (state) => {
      state.memberGroups.isLoading = true;
      state.memberGroups.list = [];
      state.memberGroups.error = null;
    },
    fetchGroupsSuccess: (state, { payload }) => {
      state.memberGroups.isLoading = false;
      state.memberGroups.list = payload;
      state.memberGroups.error = null;
    },
    fetchGroupsFailure: (state, { payload }) => {
      state.memberGroups.isLoading = false;
      state.memberGroups.list = [];
      state.memberGroups.error = payload;
    },
    addGroupRequest: (state) => {
      state.memberGroups.isAddLoading = LoadingStatus.loading;
      state.memberGroups.addError = null;
    },
    addGroupSuccess: (state, { payload }) => {
      state.memberGroups.isAddLoading = LoadingStatus.succeeded;
      state.memberGroups.list = [...state.memberGroups.list, payload];
    },
    addGroupFailure: (state, { payload }) => {
      state.memberGroups.isAddLoading = LoadingStatus.failed;
      state.memberGroups.addError = payload;
    },
    clearAddGroupLoading: (state) => {
      state.memberGroups.isAddLoading = LoadingStatus.idle;
    },
    deleteGroup: (state, { payload }) => {
      state.memberGroups.list = produce(state.memberGroups.list, (draft) => {
        const idx = draft.findIndex((g) => g.groupId === payload.deletedGroupId);
        if (idx !== -1) draft.splice(idx, 1);
      });

      state.members = state.members.map((m) =>
        produce(m, (draft) => {
          if (draft.groupId === payload.deletedGroupId) {
            draft.groupId = payload.replacementGroupId;
          }
        })
      );

      if (state.activeFilters.groupId === payload.deletedGroupId) {
        state.activeFilters.groupId = payload.replacementGroupId;
      }
    },
    addMembers: (state, { payload }) => {
      state.members = [...state.members, ...payload.successfullyAdded];
      state.membersFailed = payload.failedToAdd;
      state.statusTotals.INVITED += payload.successfullyAdded.length;

      if (state.finchIntegration.currentConnection) {
        state.finchIntegration.invitedMembers = payload.successfullyAdded;
      }
    },
    updateMemberInformation: (state, { payload }) => {
      const changedMemberIndex = state.members.findIndex(
        (member) => member.userID === payload.userID
      );

      const targetMember = state.members[changedMemberIndex];

      if (payload.groupId && targetMember.groupId !== payload.groupId) {
        const increaseGroupIdx = state.memberGroups.list.findIndex(
          (g) => g.groupId === payload.groupId
        );

        const decreaseGroupIdx = state.memberGroups.list.findIndex(
          (g) => g.groupId === targetMember.groupId
        );

        state.memberGroups.list = produce(state.memberGroups.list, (draft) => {
          draft[increaseGroupIdx].memberCount++;
          draft[decreaseGroupIdx].memberCount--;
        });
      }

      state.members[changedMemberIndex] = {
        ...state.members[changedMemberIndex],
        ...payload
      };
    },
    addCancellationReason: (state, { payload }) => {
      state.cancellationReasons.push(payload);
    },
    setCancellationReasons: (state, { payload }) => {
      state.cancellationReasons = payload;
    },
    // Add emails
    addMemberEmails: (state, { payload }) => {
      state.inviteEmails = payload.emails;
      state.invalidEmails = payload.invalidEmails;
    },

    cancelMemberRequest: (state) => {
      state.isLoading = true;
    },
    cancelMemberSuccess: (state) => {
      state.isLoading = false;
    },
    cancelMemberFailure: (state, { payload }) => {
      state.error = true;
      state.membersFailed = [payload];
      state.isLoading = false;
    },

    // send email invites
    sendEmailInvitesRequest: (state) => {
      state.isLoading = true;

      if (state.finchIntegration.currentConnection) {
        state.finchIntegration.loading.importingMembers = LoadingStatus.loading;
      }
    },
    sendEmailInvitesSuccess: (state) => {
      state.isLoading = false;
      state.inviteEmails = [];

      if (state.finchIntegration.currentConnection) {
        state.finchIntegration.loading.importingMembers = LoadingStatus.succeeded;
      }
    },
    sendEmailInvitesFailure: (state, { payload }) => {
      state.isLoading = false;
      state.membersFailed = payload;

      if (state.finchIntegration.currentConnection) {
        state.finchIntegration.loading.importingMembers = LoadingStatus.failed;
      }
    },

    // fetch contract for admin
    fetchContractForAdminRequest: (state) => {
      state.isLoading = true;
      state.isLoadingContract = true;
    },
    fetchContractForAdminSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingContract = false;
      state.contractForAdmin = {
        ...payload,
        isHybrid: isCompanyContractHybrid(payload)
      };
    },
    fetchContractForAdminFailure: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingContract = false;
      state.error = payload;
      state.errorMessage = 'unable to load admin contract';
    },

    updateCurrentFilter: (state, { payload }) => {
      state.dashboardSettings.filters[payload.filter] = payload.value;
    },

    updateSort: (state, { payload }) => {
      state.dashboardSettings.sort.value = payload;
    },

    updateSortDirection: (state, { payload }) => {
      state.dashboardSettings.sort.direction = payload;
    },

    updateSearchValue: (state, { payload }) => {
      state.dashboardSettings.searchValue = payload;
    },

    updateTotalMembers: (state, { payload }) => {
      state.totalMembers = payload;
    },

    updateInviteSettings: (state, { payload }) => {
      state.inviteSettings = {
        ...state.inviteSettings,
        ...payload
      };
    },
    // Get member costs
    fetchMemberCostsRequest: (state) => {
      state.isLoadingCosts = true;
    },
    fetchMemberCostsSuccess: (state, { payload }) => {
      state.isLoadingCosts = false;
      state.memberCosts = payload;
      state.monthlyTotal = payload.reduce(
        (acc, memberCost) =>
          acc + memberCost.companyMonthlyCost + memberCost.memberMonthlyCost,
        0
      );
    },
    fetchMemberCostsFailure: (state) => {
      state.isLoadingCosts = false;
    },

    // Get draft members
    fetchDraftMembersRequest: (state) => {
      state.isLoading = true;
      state.isLoadingDraftMembers = true;
    },
    fetchDraftMembersSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
      state.draftMembers = payload.map((member) => ({
        ...member,
        status: 'draft',
        plan: member?.companyPlan
      }));
    },
    fetchDraftMembersFailure: (state) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },

    // Create new draft members
    addDraftMembersRequest: (state) => {
      state.isLoading = true;
      state.isLoadingDraftMembers = true;
    },
    addDraftMemberSuccess: (state, { payload }) => {
      state.inviteEmails = [];
      state.draftMembers = [
        ...state.draftMembers,
        { ...payload, status: 'draft', plan: payload?.companyPlan }
      ];
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },
    addDraftMemberFailure: (state, { payload }) => {
      state.error = true;
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
      state.draftMembersFailed = [...(state?.draftMembersFailed || []), payload];
      state.errorMessage = `Unable to add the following members: ${state.draftMembersFailed.join(
        ', '
      )}`;
    },
    addedAllDraftMembersSuccess: (state) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },
    updateShouldRefreshMembers: (state, { payload }) => {
      state.shouldRefreshMembers = payload;
    },
    // Delete draft member
    deleteDraftMemberRequest: (state) => {
      state.isLoading = true;
      state.isLoadingDraftMembers = true;
    },
    deleteDraftMemberSuccess: (state, { payload }) => {
      state.draftMembers = state.draftMembers.filter((m) => m.id !== payload);
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },
    deleteDraftMemberFailure: (state) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },

    // Invite all draft members
    inviteDraftMemberRequest: (state) => {
      state.isLoading = true;
    },
    inviteDraftMemberSuccess: (state) => {
      state.isLoading = false;
    },
    inviteDraftMemberFailure: (state) => {
      state.isLoading = false;
    },
    removeDraftMembersAfterInvite: (state, { payload }) => {
      state.draftMembers = state.draftMembers.filter(
        (draftMember) => !payload.some((invited) => invited.email === draftMember.email)
      );
    },

    // Fetch Connections
    fetchConnectionsRequest: (state) => {
      state.finchConnectionsLoading = LoadingStatus.loading;
    },
    fetchConnectionsSuccess: (state, { payload }) => {
      state.finchConnectionsLoading = LoadingStatus.succeeded;
      state.finchConnections = payload;
    },
    fetchConnectionsFailure: (state) => {
      state.finchConnectionsLoading = LoadingStatus.failed;
      state.errorMessage = 'Unable to fetch connections';
      state.error = true;
    },

    // Create Connection
    createConnectionRequest: (state) => {
      state.finchIntegration.loading.createConnection = LoadingStatus.loading;
    },
    createConnectionSuccess: (state, { payload }) => {
      state.finchIntegration.loading.createConnection = LoadingStatus.succeeded;
      state.finchConnections = [payload, ...state.finchConnections];
    },
    createConnectionFailure: (state) => {
      state.finchIntegration.loading.createConnection = LoadingStatus.failed;
      state.errorMessage = 'Unable to create connection';
      state.error = true;
    },

    selectConnection: (state, { payload: connectionId }) => {
      state.finchIntegration.currentConnection = state.finchConnections.find(
        (connection) => connection.id === connectionId
      );

      state.finchIntegration.loading.fetchRemoteIndividualsFromConnection =
        LoadingStatus.idle;
    },

    // Remove Connection
    removeConnectionRequest: (state) => {
      state.finchConnectionsLoading = LoadingStatus.loading;
    },
    removeConnectionSuccess: (state, { payload: connectionId }) => {
      state.finchConnectionsLoading = LoadingStatus.succeeded;
      state.finchConnections = state.finchConnections.filter(
        (connection) => connection.id !== connectionId
      );
    },
    removeConnectionFailure: (state) => {
      state.finchConnectionsLoading = LoadingStatus.failed;
      state.errorMessage = 'Unable to remove connection';
      state.error = true;
    },

    // Fetch Remote Individuals From Connection
    fetchRemoteIndividualsFromConnectionRequest: (state) => {
      state.finchIntegration.loading.fetchRemoteIndividualsFromConnection =
        LoadingStatus.loading;
    },
    fetchRemoteIndividualsFromConnectionSuccess: (state, { payload }) => {
      state.finchIntegration.loading.fetchRemoteIndividualsFromConnection =
        LoadingStatus.succeeded;
      state.finchIntegration.remoteIndividuals.available = payload.availableIndividuals;
      state.finchIntegration.remoteIndividuals.unmappable = payload.unmappableIndividuals;
    },
    fetchRemoteIndividualsFromConnectionFailure: (state, { payload }) => {
      state.finchIntegration.loading.fetchRemoteIndividualsFromConnection =
        LoadingStatus.failed;
      state.finchIntegration.error.fetchRemoteIndividualsFromConnection = payload;
      state.errorMessage = 'Unable to fetch remote individuals from connection';
      state.error = true;
    },
    fetchRemoteIndividualsFromConnectionNotAuthorized: (state) => {
      state.finchIntegration.loading.fetchRemoteIndividualsFromConnection =
        LoadingStatus.failed;
      state.finchIntegration.error.fetchRemoteIndividualsFromConnection =
        FinchConnectionError.notAuthorized;
      state.errorMessage =
        'Connection no longer valid. Please, remake connection to use this feature.';
      state.error = true;
    },

    clearFinchIntegrationData: (state) => {
      state.membersToImport = [];
      state.finchIntegration = finchIntegrationInitialState;
    },

    // DIV
    clearError: (state) => {
      state.error = false;
      state.membersFailed = [];
      state.draftMembersFailed = [];
      state.errorMessage = null;
      state.inviteEmails = [];
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setError: (state) => {
      state.error = true;
    },

    updatePage: (state, { payload }) => {
      state.dashboardSettings.page = payload;
    },
    updatePageSize: (state, { payload }) => {
      state.dashboardSettings.pageSize = payload;
    },

    updateMonthlyTotal: (state, { payload }) => {
      state.monthlyTotal = payload;
    },
    updateStatusTotals: (state, { payload }) => {
      state.statusTotals = payload;
    },
    updateMembersToImport: (state, { payload }) => {
      state.membersToImport = payload;
    }
  },
  extraReducers: {
    [fetchMembers.pending]: (state) => {
      state.isLoadingMembers = true;
      state.errorMessage = null;
    },
    [fetchMembers.fulfilled]: (state, { payload }) => {
      state.isLoadingMembers = false;
      state.members = payload.content;
      state.totalMembers = payload.totalSize;
      state.totalPages = payload.totalPages || 1;
    },
    [fetchMembers.rejected]: (state) => {
      state.isLoadingMembers = false;
      state.errorMessage = 'Unable to load the company members';
      state.error = true;
    },
    [fetchMemberCountsByStatus.pending]: (state) => {
      state.isLoadingMemberStatusTotals = true;
      state.errorMessage = null;
    },
    [fetchMemberCountsByStatus.fulfilled]: (state, { payload }) => {
      state.statusTotals = payload;
      state.isLoadingMemberStatusTotals = false;
      state.statusTotalsLoaded = true;
    },
    [fetchMemberCountsByStatus.rejected]: (state) => {
      state.isLoadingMemberStatusTotals = false;
      state.errorMessage = 'Error loading member counts';
      state.error = true;
      state.statusTotalsLoaded = true;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const addMemberEmails = (emails) => (dispatch) => {
  dispatch(
    actions.addMemberEmails({
      emails,
      invalidEmails: (emails || []).filter((email) => !isValidEmail(email.value.trim()))
    })
  );
};

export const groupAdd = (groupName) => async (dispatch) => {
  dispatch(actions.addGroupRequest());

  try {
    const request = {
      name: groupName,
      members: []
    };

    const response = await post('/groups', request, { api: 'rh' });

    dispatch(actions.addGroupSuccess(response));
  } catch (error) {
    dispatch(actions.addGroupFailure(error));
  }
};

export const clearAddGroupLoading = () => (dispatch) => {
  dispatch(actions.clearAddGroupLoading());
};

export const groupDeleted = (groupId, replacementId) => (dispatch) => {
  dispatch(
    actions.deleteGroup({
      deletedGroupId: groupId,
      replacementGroupId: replacementId
    })
  );
};

export const addMembers = (membersToAdd) => async (dispatch) => {
  dispatch(actions.addMembers(membersToAdd));
};

const generateErrorMessage = (emails) =>
  `Failed to add the following members: ${emails.join(
    ', '
  )}. Please contact support if the error persists.`;

export const failedToAddMembers = (members) => async (dispatch) => {
  if (members.failedToAdd && members.failedToAdd.length > 0) {
    dispatch(
      actions.setErrorMessage(
        generateErrorMessage(members.failedToAdd.map((m) => m.email))
      )
    );
    dispatch(actions.setError());
  }
};

export const setMembersToImport = (membersToImport) => async (dispatch) => {
  dispatch(actions.updateMembersToImport(membersToImport));
};

export const inviteMembers =
  (
    members,
    manuallyAddedMemberEmails,
    membersCanAddDependants,
    startDate,
    companyID,
    plan,
    groupId,
    importedIndividuals,
    hrPlatformConnectionId
  ) =>
  async (dispatch) => {
    dispatch(actions.sendEmailInvitesRequest());

    const planMembers = (members || []).map(({ email }) => email);
    const newMembersParsed = manuallyAddedMemberEmails
      .filter((email) => planMembers.indexOf(email.value) < 0)
      .map((email) => {
        const memberParsed = {
          email: email.value,
          startDate,
          group: groupId
        };
        if (plan) {
          memberParsed.companyPlan = plan;
        }
        return memberParsed;
      });

    const importedMembers = (importedIndividuals || [])
      .filter((individual) => planMembers.indexOf(individual.email) < 0)
      .map((individual) => ({
        email: individual.email,
        startDate,
        group: groupId,
        remoteIndividualId: individual.remoteId,
        companyPlan: plan
      }));

    const body = {
      companyID,
      membersCanAddDependants,
      importedFromConnectionId: hrPlatformConnectionId,
      newUsers: [...newMembersParsed, ...importedMembers]
    };

    try {
      dispatch(actions.sendEmailInvitesRequest());
      const members = await post('/rh/members/add-by-admin', body, { api: 'rh' });
      dispatch(actions.sendEmailInvitesSuccess());
      dispatch(actions.addMembers(members));
      if (members.successfullyAdded.length > 0) {
        dispatch(actions.updateShouldRefreshMembers(true));
      }
      dispatch(failedToAddMembers(members));
    } catch (error) {
      const emails = newMembersParsed.map(({ email }) => ({ email }));
      dispatch(actions.setErrorMessage(generateErrorMessage(emails)));
      dispatch(actions.sendEmailInvitesFailure(emails));
    }
  };

export const fetchDraftMembers = (companyContractId) => async (dispatch) => {
  dispatch(actions.fetchDraftMembersRequest());
  try {
    const response = await get(
      `/rh/draftMember/all?companyContractId=${companyContractId}`,
      { api: 'rh' }
    );
    dispatch(actions.fetchDraftMembersSuccess(response));
  } catch (error) {
    dispatch(actions.fetchDraftMembersFailure(error));
  }
};

export const addDraftMembers =
  (companyContractId, memberEmails, startDate, companyPlanId) => async (dispatch) => {
    dispatch(actions.addDraftMembersRequest());
    memberEmails.forEach(async (email) => {
      try {
        const draftMember = await post(
          `/rh/draftMember`,
          {
            companyContractId,
            companyPlanId,
            startDate,
            email: email?.value
          },
          { redirectOnUnauthorized: true, api: 'rh' }
        );
        dispatch(actions.addDraftMemberSuccess(draftMember));
      } catch {
        dispatch(actions.addDraftMemberFailure(email?.value));
      }
    });

    dispatch(actions.addedAllDraftMembersSuccess());
  };

export const deleteDraftMember = (id) => async (dispatch) => {
  dispatch(actions.deleteDraftMemberRequest);
  try {
    await del(`/rh/draftMember?id=${id}`, null, {
      redirectOnUnauthorized: true,
      api: 'rh'
    });
    dispatch(actions.deleteDraftMemberSuccess(id));
  } catch (error) {
    dispatch(actions.deleteDraftMemberFailure(error, id));
  }
};

export const inviteDraftMembers = (companyContractId) => async (dispatch) => {
  dispatch(actions.inviteDraftMemberRequest());

  try {
    const invited = await post(
      `/rh/draftMember/invite?companyContractId=${companyContractId}`,
      null,
      { redirectOnUnauthorized: true, api: 'rh' }
    );
    dispatch(actions.removeDraftMembersAfterInvite(invited.successfullyAdded));
    invited.successfullyAdded.forEach((member) => {
      member.status = 'invited';
      member.monthlyCost = 0;
      member.contractCost = 0;
    });
    dispatch(actions.addMembers(invited));
    dispatch(actions.sendEmailInvitesSuccess());
  } catch (error) {
    dispatch(actions.inviteDraftMemberFailure(error));
  }
};

export const fetchMemberCosts = (companyContractId) => async (dispatch) => {
  dispatch(actions.fetchDraftMembersRequest());
  try {
    const response = await get(
      `/rh/companies/member-cost-details?companyContractId=${companyContractId}`,
      { api: 'rh' }
    );
    dispatch(actions.fetchMemberCostsSuccess(response));
  } catch (error) {
    dispatch(actions.fetchMemberCostsFailure(error));
  }
};

export const fetchMemberTotalsByStatus = (companyContractId) => async (dispatch) => {
  dispatch(actions.fetchDraftMembersRequest());
  try {
    const response = await get(
      `/rh/companies/member-count-by-status?companyContractId=${companyContractId}`,
      { api: 'rh' }
    );
    dispatch(actions.fetchMemberCostsSuccess(response));
  } catch (error) {
    dispatch(actions.fetchMemberCostsFailure(error));
  }
};

export const updateGroupMember = (memberId, groupId) => async (dispatch) => {
  await put(`/groups/${groupId}`, { members: [memberId] }, { api: 'rh' });

  dispatch(
    actions.updateMemberInformation({
      userID: memberId,
      groupId
    })
  );
};

export const cancelMember =
  (member, canceledDate, cancellationReason) => async (dispatch) => {
    dispatch(actions.cancelMemberRequest());
    try {
      const body = {
        userID: member.userID,
        canceledDate,
        cancellationReason
      };
      await post('/rh/contracts/member/cancel?sendEmail=true', body, {
        api: 'rh'
      });
      dispatch(actions.cancelMemberSuccess());
      const todaysDate = new Date();
      const canceledDateAsDate = new Date(canceledDate);
      dispatch(
        actions.updateMemberInformation({
          userID: member.userID,
          insuranceCanceledDate: canceledDate,
          status: canceledDateAsDate > todaysDate ? 'cancelling' : 'canceled'
        })
      );
    } catch (error) {
      dispatch(
        actions.setErrorMessage(
          `Failed to cancel member ${
            member.firstName ? `${member.firstName} ${member.lastName}` : member.email
          }.`
        )
      );
      dispatch(actions.cancelMemberFailure(member));
    }
  };

export const clearError = () => async (dispatch) => {
  dispatch(actions.clearError());
};

export const fetchContractForAdmin = () => async (dispatch) => {
  dispatch(actions.fetchContractForAdminRequest());

  try {
    const response = await get(`/rh/contracts/company`, {
      api: 'rh'
    });
    dispatch(actions.fetchContractForAdminSuccess(response));
  } catch (error) {
    dispatch(actions.fetchContractForAdminFailure(error));
  }
};

export const fetchCancellationReasons = () => async (dispatch) => {
  const response = await get(`/rh/companies/cancellation/reasons/list`, { api: 'rh' });
  dispatch(actions.setCancellationReasons(response.reasons));
};

export const fetchGroups = () => async (dispatch) => {
  dispatch(actions.fetchGroupsRequest());

  try {
    const response = await get(`/groups`, { api: 'rh' });
    dispatch(actions.fetchGroupsSuccess(response));
  } catch (error) {
    dispatch(actions.fetchGroupsFailure(error));
  }
};

export const updateCurrentFilter = (filterKey, filterValue) => (dispatch) => {
  dispatch(actions.updateCurrentFilter(filterKey, filterValue));
};

export const updateSearchValue = (searchValue) => (dispatch) => {
  dispatch(actions.updateSearchValue(searchValue));
};

export const updateInviteSettings = (newSettings) => (dispatch) => {
  dispatch(actions.updateInviteSettings(newSettings));
};

export const addCancellationReason = (reason) => async (dispatch) => {
  await post('/rh/companies/cancellation/reasons', { api: 'rh' }, { reason });
  dispatch(actions.addCancellationReason(reason));
};

export const updatePage = (page) => (dispatch) => {
  dispatch(actions.updatePage(page));
};

export const updatePageSize = (page) => (dispatch) => {
  dispatch(actions.updatePageSize(page));
};

export const updateSort = (key, direction) => (dispatch) => {
  dispatch(actions.updateSort(key));
  dispatch(actions.updateSortDirection(direction));
};

export const clearSort = () => (dispatch) => {
  dispatch(actions.updateSort(null));
  dispatch(actions.updateSortDirection(null));
};

export const fetchConnections = () => async (dispatch) => {
  dispatch(actions.fetchConnectionsRequest());

  try {
    const response = await get('/rh/finch-company-connections', { api: 'rh' });
    dispatch(actions.fetchConnectionsSuccess(response));
  } catch (error) {
    dispatch(actions.fetchConnectionsFailure(error));
  }
};

export const createConnection =
  (code, shouldSelectCreatedConnection) => async (dispatch) => {
    dispatch(actions.createConnectionRequest());

    try {
      const response = await post(
        '/rh/finch-company-connections',
        { code },
        { api: 'rh' }
      );
      dispatch(actions.createConnectionSuccess(response));

      if (shouldSelectCreatedConnection) dispatch(actions.selectConnection(response.id));
    } catch (error) {
      dispatch(actions.createConnectionFailure(error));
    }
  };

export const removeConnection = (connectionId) => async (dispatch) => {
  dispatch(actions.removeConnectionRequest());

  try {
    await del(`/rh/finch-company-connections/${connectionId}`, null, { api: 'rh' });
    dispatch(actions.removeConnectionSuccess(connectionId));
  } catch (error) {
    dispatch(actions.removeConnectionFailure(error));
  }
};

export const updateCurrentConnection = (connectionId) => async (dispatch) => {
  dispatch(actions.selectConnection(connectionId));
};

export const clearFinchIntegrationData = () => async (dispatch) => {
  dispatch(actions.clearFinchIntegrationData());
};

export const fetchRemoteIndividualsFromConnection =
  (connectionId) => async (dispatch) => {
    dispatch(actions.fetchRemoteIndividualsFromConnectionRequest());

    try {
      const response = await get(
        `/rh/finch-company-connections/${connectionId}/remote-individuals`,
        { redirectOnUnauthorized: false, api: 'rh' }
      );
      dispatch(actions.fetchRemoteIndividualsFromConnectionSuccess(response));
    } catch (error) {
      if (error && error.status === 401) {
        dispatch(actions.fetchRemoteIndividualsFromConnectionNotAuthorized());
      } else {
        dispatch(actions.fetchRemoteIndividualsFromConnectionFailure(error));
      }
    }
  };

export const updateShouldRefreshMembers = (value) => async (dispatch) => {
  dispatch(actions.updateShouldRefreshMembers(value));
};
