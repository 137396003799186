import { createSlice } from '@reduxjs/toolkit';
import { post, put, del } from 'api/fetch';
import { updatePlanholderInfo } from 'state/planholder';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'memberActivation/step2',
  initialState: {
    isLoading: false,
    error: null,
    loadingDependantID: null,
    isValid: false
  },
  reducers: {
    // addDependant
    addDependantRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    addDependantSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    addDependantFailure: (state, { payload }) => {
      state.isLoading = true;
      state.error = payload;
    },
    // deleteDependant
    deleteDependantRequest: (state, { payload }) => {
      state.loadingDependantID = payload;
      state.error = null;
    },
    deleteDependantSuccess: (state) => {
      state.loadingDependantID = null;
      state.error = null;
    },
    deleteDependantFailure: (state, { payload }) => {
      state.loadingDependantID = null;
      state.error = payload;
    },
    // deleteDependant
    editDependantRequest: (state, { payload }) => {
      state.loadingDependantID = payload;
      state.error = null;
    },
    editDependantSuccess: (state) => {
      state.loadingDependantID = null;
      state.error = null;
    },
    editDependantFailure: (state, { payload }) => {
      state.loadingDependantID = null;
      state.error = payload;
    },
    setValidity: (state, { payload }) => {
      state.isValid = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const addDependant = (info) => async (dispatch) => {
  dispatch(actions.addDependantRequest());

  const body = {
    firstName: info?.firstName,
    lastName: info?.lastName,
    dateOfBirth: info?.dateOfBirth,
    relationship: info?.relationship,
    citizenship: info?.citizenshipCountry,
    email: info?.email,
    gender: info?.gender?.toUpperCase(),
    passportNumber: info?.passportNumber,
    occupation: info?.occupation
  };

  try {
    const response = await post(`/rh/plan-holders/v2/dependant`, body, {
      api: 'rh',
      redirectOnUnauthorized: false
    });
    await dispatch(updatePlanholderInfo({ dependants: response.dependants }));
    dispatch(actions.addDependantSuccess());
  } catch (error) {
    dispatch(actions.addDependantFailure(error));
  }
};

export const deleteDependant = (id) => async (dispatch, useState) => {
  if (useState().remoteHealth.memberActivation.step2.loadingDependantID) return;

  dispatch(actions.deleteDependantRequest(id));

  try {
    const response = await del(`/rh/plan-holders/v2/dependant/${id}`, null, {
      api: 'rh',
      redirectOnUnauthorized: false
    });
    await dispatch(updatePlanholderInfo({ dependants: response.dependants }));
    dispatch(actions.deleteDependantSuccess());
  } catch (error) {
    dispatch(actions.deleteDependantFailure(error));
  }
};

export const editDependant = (info) => async (dispatch, useState) => {
  if (useState().remoteHealth.memberActivation.step2.loadingDependantID) return;

  dispatch(actions.editDependantRequest(info.dependantID));

  const body = {
    firstName: info?.firstName,
    lastName: info?.lastName,
    dateOfBirth: info?.dateOfBirth,
    relationship: info?.relationship,
    citizenship: info?.citizenshipCountry,
    email: info?.email,
    gender: info?.gender?.toUpperCase(),
    passportNumber: info?.passportNumber,
    occupation: info?.occupation
  };

  try {
    const response = await put(
      `/rh/plan-holders/v2/dependant/${info.dependantID}`,
      body,
      {
        api: 'rh',
        redirectOnUnauthorized: false
      }
    );
    await dispatch(updatePlanholderInfo({ dependants: response.dependants }));
    dispatch(actions.editDependantSuccess());
  } catch (error) {
    dispatch(actions.editDependantFailure(error));
  }
};

export const setValidity = (isValid) => (dispatch) => {
  dispatch(actions.setValidity(isValid));
};
