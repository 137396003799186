import { createSlice } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import history from 'routing/history';
import * as Sentry from '@sentry/react';
import { decodeJWT, clearJWT, createJWT, pushAccessTokenToJWT } from 'auth';
import { fireLoginTrackingEvent } from 'api/analytics';
import { get, post } from 'api/fetch';
import { fetchPlanholderInfo as fetchRHPlanholderInfo } from 'state/planholder';
import { updateCompanyInfo } from 'state/company';
import { fetchMemberWebsiteData } from 'state/websiteData';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    isInitialized: false,
    isInitInProgress: false,
    initializedProduct: null,
    user: {
      isLoading: false,
      isUpdated: false,
      loadingField: false,
      error: null,
      isAuthenticated: false,
      accessToken: null,
      userID: null,
      citizenshipCountry: '',
      dateOfBirth: '',
      email: '',
      firstName: '',
      lastName: '',
      gender: '',
      consentToTermsAndConditions: '',
      consentToMarketing: ''
    },
    facebookLogin: {
      isLoading: false,
      error: false,
      user: null
    }
  },
  reducers: {
    // initialize
    initializeRequest: (state) => {
      state.isInitInProgress = true;
    },
    initializeSuccess: (state, { payload }) => {
      state.isInitInProgress = false;
      state.isInitialized = true;
      state.initializedProduct = payload.initializedProduct || state.initializedProduct;
      state.user = {
        ...state.user,
        isAuthenticated: true,
        ...payload.user
      };
    },
    initializeFailure: (state, { payload }) => {
      state.isInitInProgress = false;
      state.isInitialized = true;
      state.user = {
        isAuthenticated: false,
        role: null
      };
      state.error = payload;
    },
    updateAuthTokenRequest: (state) => {
      state.user.isLoading = true;
    },
    updateAuthTokenSuccess: (state) => {
      state.user.isLoading = false;
      state.user.isUpdated = true;
    },
    updateAuthTokenFailure: (state, { payload }) => {
      state.user.isLoading = false;
      state.error = payload;
    },
    updateAppInfo: (state, { payload }) => {
      state.user = { ...state.user, ...payload.user };
      state.isInitialized = payload.isInitialized || state.isInitialized;
      state.initializedProduct = payload.initializedProduct || state.initializedProduct;
    },
    // logout
    logOutUser: (state) => {
      state.isInitInProgress = false;
      state.isInitialized = true;
      state.user = {
        isAuthenticated: false,
        role: null
      };
    },
    // facebookLogin
    facebookLoginRequest: (state) => {
      state.facebookLogin.isLoading = true;
      state.facebookLogin.error = null;
    },
    facebookLoginSuccess: (state, { payload }) => {
      state.facebookLogin.isLoading = false;
      state.facebookLogin.error = null;
      state.facebookLogin.user = payload;
    },
    facebookLoginFailure: (state, { payload }) => {
      state.facebookLogin.isLoading = false;
      state.facebookLogin.error = payload;
      state.facebookLogin.user = null;
    },
    setUserIsAmbassador: (state, { payload }) => {
      state.user.isAmbassador = payload;
    }
  }
});

export default slice.reducer;

const { actions } = slice;

// ================== Util ================== //

export const tokenExists = () => {
  const userID = decodeJWT('userID');
  const accessToken = decodeJWT('accessToken');
  return !!userID && !!accessToken;
};

export const isUserAuthenticated = (user) => (dispatch) => {
  const { id } = user;
  const userID = decodeJWT('userID');

  if (userID !== id) {
    clearJWT();
    dispatch(actions.initializeFailure());
  }
};

// ================== Actions ================== //

export const initialize = () => async (dispatch, getState) => {
  try {
    if (tokenExists()) {
      const companyID = decodeJWT('companyID');
      const accessToken = decodeJWT('accessToken');

      dispatch(actions.initializeRequest());

      await dispatch(fetchRHPlanholderInfo(true));
      const { planholder } = getState().remoteHealth.planholder;

      Sentry.setUser({ id: planholder?.userID });

      if (companyID) {
        Sentry.setTag('company', companyID);
        dispatch(updateCompanyInfo({ companyID }));
      }

      dispatch(fetchMemberWebsiteData());

      dispatch(
        actions.initializeSuccess({
          user: {
            userID: planholder.userID,
            email: planholder?.email,
            accessToken
          },
          initializedProduct: 'remote_health'
        })
      );
      try {
        posthog.identify(planholder.userID, {
          email: planholder?.email
        });
      } catch (e) {
        // ignore
      }
    } else {
      dispatch(actions.initializeFailure());
      posthog.reset();
    }
  } catch (error) {
    clearJWT();
    dispatch(actions.initializeFailure(error));
    posthog.reset();
  }
};

export const updateAuthToken = () => async (dispatch) => {
  try {
    if (!tokenExists()) return;

    dispatch(actions.updateAuthTokenRequest());

    const response = await get('/users/refreshAuthToken');
    pushAccessTokenToJWT(response);

    dispatch(actions.updateAuthTokenSuccess());
  } catch (error) {
    dispatch(actions.updateAuthTokenFailure(error));
  }
};

export const logOutUser = (redirect) => (dispatch) => {
  clearJWT();

  window.Intercom('shutdown');
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID
  });

  dispatch({ type: 'RESET_APP' });
  dispatch(actions.logOutUser());

  posthog.reset();

  if (redirect !== 'none') {
    history.push(redirect || '/');
  }
};

export const facebookLogin = (facebookResponse) => async (dispatch) => {
  if (!facebookResponse.userID) {
    return;
  }

  dispatch(actions.facebookLoginRequest());

  const body = {
    facebookID: facebookResponse.userID,
    accessToken: facebookResponse.accessToken,
    profilePictureUrl: facebookResponse.picture.data.url,
    firstName: facebookResponse.name ? facebookResponse.name.split(' ')[0] : null,
    lastName: facebookResponse.name ? facebookResponse.name.split(' ')[1] : null,
    email: facebookResponse.email ? facebookResponse.email : null
  };

  try {
    const response = await post('/users/login', body);
    await createJWT({
      userID: response.userID,
      accessToken: response.accessToken
    });
    dispatch(actions.facebookLoginSuccess(body));
    fireLoginTrackingEvent(response.userID, 'facebook');
  } catch (error) {
    dispatch(actions.facebookLoginFailure(error));
  }
};

export const { setUserIsAmbassador } = slice.actions;
